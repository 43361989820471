<template>
  <el-dialog title="转办" width="620px" :visible="transferShow" :close-on-click-modal="false" @close='closeDialog'>
    <el-form :model="info" ref="info" label-width="120px">
      <template>
        <el-form-item label="问题类型" prop="handleManageId" :rules="[
          { required: true, message: '请选择问题类型', trigger: 'change' }
        ]">
          <el-select v-model="info.handleManageId" filterable placeholder="请选择问题类型" @change="safeManageChange">
            <el-option v-for="(item, index) in safeManageList" :label="item.safeManageName" :value="item.safeManageId" :key="index"></el-option>
          </el-select>
        </el-form-item>
      </template>
      <el-form-item label="问题处理人" prop="receiveUserId" :rules="[
          { required: true, message: '请选择问题处理人', trigger: 'change' }
        ]">
        <el-select
          v-model="info.receiveUserId"
          filterable placeholder="请选择处理人"
          :disabled="info.handleManageId === ''">
          <el-option
            v-for="item in userList"
            :key="item.userId"
            :label="item.userName"
            :value="item.userId"
          >
            <div class="option-detail">
              <p>{{item.userName}}</p>
              <p>{{item.deptName}}</p>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item prop="" label="备注">
        <el-input type="textarea" v-model="info.handleBak" placeholder="请输入备注"/>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" :disabled="submitShow">确定</el-button>
        <el-button @click="closeDialog">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'IssueATransfer',
  props: {
    transferShow: Boolean,
    issueId: String,
    transInfo: {
      type: Object,
      default: () => {
        return {
          projectId: '',
          currentSafeManageId: '',
          currentBusinessType: '',
          currentHandleUserId: ''
        }
      }
    }
  },
  watch: {
    transferShow (val) {
      if (val) {
        this.loadSafeManageList()
        this.info.handleManageId = this.transInfo.currentSafeManageId
        this.loadUserList()
      }
    }
  },
  data () {
    return {
      safeManageList: [],
      userList: [],
      info: {
        handleManageId: '',
        receiveUserId: '',
        handleBak: ''
      },
      submitShow: false
    }
  },
  created () {
    // ...
  },
  methods: {
    loadSafeManageList () {
      this.$axios.get(this.$apis.patrol.selectSafeManageList).then(res => {
        this.safeManageList = res || []
      })
    },
    loadUserList () {
      this.$axios.get(this.$apis.patrol.selectUserList, {
        safeManageId: this.info.handleManageId,
        projectId: this.transInfo.projectId,
        userType: 3
      }).then(res => {
        this.userList = res || []
      })
    },
    safeManageChange (val) {
      this.info.receiveUserId = ''
      if (val) {
        this.loadUserList()
      }
    },
    onSubmit () {
      this.$refs.info.validate(valid => {
        if (valid) {
          this.submitShow = true
          this.$axios.post(this.$apis.patrol.transferIssue, {
            ...this.info,
            issueId: this.issueId
          }).then(() => {
            this.$message.success('转办成功')
            this.$emit('success')
            this.closeDialog()
          }).finally(() => {
            this.submitShow = false
          })
        }
      })
    },
    // 关闭
    closeDialog () {
      this.$emit('update:transferShow', false)
      this.$refs.info.resetFields()
    }
  }
}
</script>

<style scoped lang="scss">
  .list {
    display: flex;
    align-items: center;
    .item {
      width: auto;
      height: 32px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #999999;
      line-height: 30px;
      padding: 0 10px;
      background: #FFFFFF;
      border: 1px solid #E1E1E1;
      margin-right: 8px;
      cursor: pointer;
      &.active {
        color: #5750EB;
        border-color: #5750EB;
      }
    }
  }

  .option-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    p {
      width: 45%;
      overflow: hidden;
      word-break: keep-all;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:last-child {
        text-align: right;
      }
    }
  }
</style>
