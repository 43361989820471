<template>
  <el-dialog title="反馈" width="660px" :visible="feedbackShow" :close-on-click-modal="false" @close='closeDialog'>
    <el-form class="global-form2" :model="info" ref="info" :rules="rules" label-width="90px">
      <el-form-item label="整改图片" prop="fileList">
        <custom-upload v-model="info.fileList" accept=".jpg,.png,.jpeg">
          <div slot="tip">支持jpg、jpeg、png格式，单张图片最大10m，最多上传6张图片</div>
        </custom-upload>
      </el-form-item>
      <el-form-item prop="receiveUserId" label="审核人">
        <el-select v-model="info.receiveUserId" placeholder="请选择审核人" filterable>
          <el-option
            v-for="item in userList"
            :key="item.userId"
            :label="item.userName"
            :value="item.userId"
          >
            <div class="option-detail">
              <p>{{item.userName}}</p>
              <p>{{item.deptName}}</p>
            </div>
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="反馈说明" prop="handleBak">
        <el-input
          type="textarea"
          maxlength="300"
          placeholder="请输入反馈说明"
          :rows="6"
          show-word-limit
          v-model="info.handleBak"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit" :disabled="submitShow">提交</el-button>
        <el-button @click="closeDialog">取消</el-button>
      </el-form-item>
    </el-form>
  </el-dialog>
</template>

<script>
export default {
  name: 'IssueFeedback',
  props: {
    feedbackShow: Boolean,
    issueInfo: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  watch: {
    feedbackShow (val) {
      if (val) {
        this.loadUserList()
      }
    }
  },
  data () {
    return {
      userList: [],
      info: {
        fileList: [],
        handleBak: '',
        receiveUserId: ''
      },
      rules: {
        fileList: [
          { required: true, message: '请上传整改图片', trigger: 'change' }
        ],
        receiveUserId: [
          { required: true, message: '请选择审核人', trigger: 'change' }
        ]
      },
      submitShow: false
    }
  },
  created () {
    // ...
  },
  methods: {
    loadUserList () {
      this.$axios.get(this.$apis.patrol.selectUserList, {
        issueId: this.issueInfo.issueId,
        projectId: this.issueInfo.projectId,
        safeManageId: this.issueInfo.currentSafeManageId,
        userType: 2
      }).then(res => {
        this.userList = res || []
        this.info.receiveUserId = this.userList.length ? this.userList[0].userId : ''
      })
    },
    onSubmit () {
      this.$refs.info.validate(valid => {
        if (valid) {
          this.submitShow = true
          this.$axios.post(this.$apis.patrol.feedbackIssue, {
            ...this.info,
            issueId: this.issueInfo.issueId
          }).then(() => {
            this.$message.success('反馈成功')
            this.info.fileList = []
            console.log(111111111)
            this.$emit('success')
            this.closeDialog()
          }).finally(() => {
            this.submitShow = false
          })
        }
      })
    },
    // 关闭
    closeDialog () {
      this.$emit('update:feedbackShow', false)
      this.$refs.info.resetFields()
    }
  }
}
</script>

<style scoped lang="scss">
  .option-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 300px;
    p {
      width: 45%;
      overflow: hidden;
      word-break: keep-all;
      white-space: nowrap;
      text-overflow: ellipsis;
      &:last-child {
        text-align: right;
      }
    }
  }
</style>
